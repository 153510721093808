import React from 'react'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import Title from 'components/Shared/Title'
import { ButtonExtLink } from 'components/Shared/_Links'

import JoinNav from 'components/Public/JoinNav'
import JoinFootnote from 'components/Public/JoinFootnote'

// ======================================
// use this when poster is image format
// ======================================
import { Image } from 'components/Shared/Image'
// import IFrame from 'components/Shared/IFrame'

// ======================================
// use this when poster is PDF format
// ======================================
// import regularClass from 'assets/files/join_regular.pdf'

import styled from 'styled-components'
const Wrapper = styled.div`
  min-height: 100%;
`

// const Poster = styled.div`
//   --width: 91%;
//   --height: 54vh;

//   ${ p => p.theme.mq.xs`
//     --height: 66vh;
//   `}
//   ${ p => p.theme.mq.sm`
//     --height: 95vh;
//   `}
//   ${ p => p.theme.mq.md`
//     --height: 105vh;
//   `}
//   ${ p => p.theme.mq.lg`
//     --height: 125vh;
//   `}

//   width: var(--width);
//   height: var(--height);
//   margin: 0 auto;
// /* 
//   #outerContainer,
//   #mainContainer {
//     background: transparent;
//   }
//   .toolbar {
//     visibility: hidden;
//   } */
// `


const Join = ({ data, location }) => {
  const {
    seo,
    imagefile: { childImageSharp: image },
  } = data
  const { title, description } = seo.frontmatter.join

  return (
    <PageContent>
      <Seo title={title} description={description} />
      <JoinNav location={location} />
      
      <Wrapper>
        <Title>
          晶晶兒童合唱團 <span>招生訊息</span>
          {/* 晶晶兒童合唱團 <span>目前無招生</span> */}
        </Title>

        {/* ======================================== */}
        {/* REGISTRATION PERIOD */}
        {/* ======================================== */}
        {/* Uncomment during registration period     */ }

        <p>2023晶晶兒童合唱團熱烈招生中!<br />
        通過音樂藝術教育，培養孩子的自信心也享受和同儕一起唱歌的樂趣。</p>

        <ButtonExtLink href='https://rb.gy/my8oo' target='_blank' rel='noreferrer' style={{ marginBottom:'2.2rem'}}>
        歡迎加入晶晶
        </ButtonExtLink>

        <p></p>
        
        {/* JPEG Format Poster */}
        {/* ======================================== */}
        <Image image={image} alt='晶晶招生訊息' />
        
        {/* PDF Format Poster*/}
        {/* ======================================== */}
        {/* <Poster>
          <embed src={`${regularClass}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&view=Fit`} type="application/pdf" width="100%" height="100%" />
          <IFrame src={regularClass} />
        </Poster> */}
        
        <JoinFootnote />

      </Wrapper>
    </PageContent>
  )
}

export default Join

export const query = graphql`
  {
    seo: mdx(frontmatter: { type: { eq: "seo" } }) {
      frontmatter {
        join {
          title
          description
        }
      }
    }
    imagefile: file(name: { eq: "2023_join" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
