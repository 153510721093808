import React from 'react'
import { Link } from 'gatsby'

// import { aboutLinks } from 'constant/links'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.nav`
  ${p => p.theme.fullbleed} 
  position: absolute;
  top: 0;
  left: 0;

  --height: 3rem;
  
  ${ p => p.theme.mq.sm`
    --height: 4rem;
  `}
  --justify-content: flex-start;
  --align-items: center;
  
  ${ p => p.theme.flex}
  width: 100%;
  height: var(--height);
  background-color: rgba(var(--color-primary-rgb), 0.2);
  padding-left: var(--padding-side);

  a {
    --font-size: clamp(1.6rem, 2vw, 2.2rem);
    display: inline-block;
    padding: 0 2.5vw;
    font-size: var(--font-size);
    line-height: var(--height);

    &:hover {
      color: var(--color-white);
      background-color: var(--color-tertiary);
    }
    &.active {
      color: var(--color-white);
      background-color: var(--color-secondary);
    }
  }
`
//#endregion


const AboutLink = ({links, location}) => {
  return (
    <Wrapper>
      {links.map((link, i) => (
        <Link to={link.path} key={`about-${i}`} className={location.pathname === link.path ? 'active' : undefined}>{link.label}</Link>
      ))}
    </Wrapper>
  )
}

export default AboutLink
