import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import styled from 'styled-components'

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
export const Image = ({image, alt=''}) => {
  return <StyledImage image={getImage(image)} alt={alt} />
}

const BgImageWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
`

export const BgImage = ({ image, alt = '' }) => {
  return (
    <BgImageWrapper>
      <StyledImage image={getImage(image)} alt={alt} />
    </BgImageWrapper>
  )
}
