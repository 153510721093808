import React, { useState } from 'react'
import { TextExtLink } from 'components/Shared/_Links'
import Contact from 'components/Contact'

import styled from 'styled-components'
const Wrapper = styled.p`
  padding-top: 3rem;

  .link {
    --background-color: var(--color-primary);
    --padding: 0 2rem; 
    --border-radius: 0.15rem;
    margin: 0 1rem;
  }
`

const JoinFootnote = () => {
  const [showContact, setShowContact] = useState(false)
  const closeContact = () => setShowContact(false)
  return (
    <>
      <Wrapper>
        若您有任何招生相關問題, 也歡迎您
        <TextExtLink className='link' onClick={e => setShowContact(true)}>
          詢問晶晶
        </TextExtLink>
        或請電晶晶辦公室 (03)666-3010 與我們聯絡。
      </Wrapper>
      {showContact && (
        <Contact showModal={showContact} closeModal={closeContact} />
      )}
    </>
  )
}

export default JoinFootnote
