import React from 'react'
import { joinLinks } from 'constant/links'

import SubLinks from 'components/Shared/SubLinks'

const JoinLinks = ({ location }) => (
  <SubLinks links={joinLinks} location={location} />
)

export default JoinLinks
